import {
  REEBELO_CURRENCY,
  ReebeloConditions,
  TagHelpers,
} from '@lambda/reebelo';
import { ProductVariant } from '@lambda/commons/apis/shopify/types/storefront';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import { chunk } from 'lodash';
import { WishlistDetails } from '@lambda/apis/src/dynamoDb/types';
import settings from '@/settings';
import { ReplacedProduct } from '../collections/catalogue/helpers';
import {
  ProductEventProperties,
  ProductListFilterSort,
  ProductListFilteredEventProperties,
  ProductListTrackRequest,
  ProductTrackRequest,
  ReebeloCareTrackRequest,
} from './types';
import { ProductDetails } from '@/components/products/PurchaseLinks';
import { SegmentEvent } from './events';
import { analytics, shouldTrack, trackEvent } from './vendor/segment/segment';
import { SearchKeys } from '../search-helpers';
import { EssentialSearchOffers } from '../collection-search-helpers';
import { logger } from '../logger';
import { TopDealProduct } from '@/components/collections/hotDealsV3/types';

const getProductUrl = (): string => {
  try {
    return window.location.href;
  } catch (e) {
    return '';
  }
};

const makeFromOffer = (
  offer:
    | ReplacedProduct
    | TopDealProduct
    | (SearchOffers & {
        batteryUpgradePct?: string | null;
        batteryUpgradePrice?: string;
      }),
): ProductEventProperties => {
  const category = offer.categories?.length
    ? offer.categories[0]
    : offer.productType;

  const currency = REEBELO_CURRENCY[settings.store];

  const price = offer.price || 0;

  const eventPayload: ProductEventProperties = {
    product_id: offer?.productId?.toString() ?? undefined,
    variant_id: offer?.variantId?.toString() ?? undefined,
    vendor: offer?.vendor ?? undefined,
    sku: offer?.sku ?? undefined,
    psku: offer?.psku ?? undefined,
    name: offer.title,
    category: category ?? undefined,
    brand: offer?.brand ?? undefined,
    color: offer?.color ?? undefined,
    condition: offer.condition || ReebeloConditions.Good,
    price,
    compare_price: offer?.comparePrice ?? undefined,
    currency,
    quantity: 1,
    images: ('images' in offer ? offer.images : [offer?.imageUrl]) || undefined,
    store: settings.store,
    url: getProductUrl(),
    timestamp: new Date().toISOString(),
    battery: {
      upgrade_pct:
        'batteryUpgradePct' in offer
          ? Number(offer?.batteryUpgradePct)
          : undefined,
      upgrade_price:
        'batteryUpgradePrice' in offer
          ? Number(offer?.batteryUpgradePrice)
          : undefined,
    },
  };

  return eventPayload;
};

const makeFromCollection = (
  collection: EssentialSearchOffers,
): ProductEventProperties => {
  const currency = REEBELO_CURRENCY[settings.store];
  const tags = TagHelpers.extractTags(collection.tags);

  const eventPayload: ProductEventProperties = {
    product_id: String(collection.productId),
    variant_id: String(collection.variantId),
    psku: collection.psku,
    category: tags.category,
    brand: tags.brand,
    color: tags.color,
    condition: tags.condition,
    price: collection.price,
    currency,
    images: [collection.imageSrc],
    store: settings.store,
    timestamp: new Date().toISOString(),
  };

  return eventPayload;
};

const makeFromProductDetails = (
  details: ProductDetails,
): ProductEventProperties => {
  const currency = REEBELO_CURRENCY[settings.store];

  const condition =
    (details.condition as ReebeloConditions) || ReebeloConditions.Good;

  const price = parseFloat(details.price);

  const eventPayload: ProductEventProperties = {
    variant_id: details.id,
    sku: details.sku || '',
    vendor: details.vendor,
    psku: details.psku || '',
    name: details.name,
    category: details.category,
    brand: details.brand,
    color: details.color || '',
    condition,
    price,
    compare_price: Number.isNaN(details.comparePrice)
      ? undefined
      : details.comparePrice,
    currency,
    quantity: 1,
    images: details?.images?.map((img) => img.url),
    store: settings.store,
    url: getProductUrl(),
    timestamp: new Date().toISOString(),
  };

  return eventPayload;
};

const makeFromProductVariant = (
  variant: ProductVariant,
  variantAttributes?: Record<string, string>[],
): ProductEventProperties => {
  const currency = REEBELO_CURRENCY[settings.store];
  const { product } = variant;
  const tags = TagHelpers.extractTags(product.tags);
  let images: string[] = [];

  if (product.images?.edges.length)
    images = product.images.edges.map((n) => n.node.url);
  else if (variant.image) images = [variant.image.url];

  const eventPayload: ProductEventProperties = {
    product_id: product.id,
    variant_id: variant.id,
    sku: variant.sku,
    vendor: product.vendor,
    psku: tags.psku,
    name: product.title.split(' - ')[0],
    category: tags.category,
    brand: tags.brand,
    color: tags.color,
    condition: tags.condition,
    price: Number(variant.priceV2.amount),
    compare_price: Number(variant.compareAtPriceV2?.amount),
    currency,
    quantity: 1,
    images,
    store: settings.store,
    url: product.onlineStoreUrl,
    timestamp: new Date().toISOString(),
    battery: {
      upgrade_pct: Number(
        variantAttributes?.find((attr) => attr.key === '_battery_upgrade_pct')
          ?.value,
      ),
      upgrade_price: Number(
        variantAttributes?.find((attr) => attr.key === '_battery_upgrade_price')
          ?.value,
      ),
    },
  };

  return eventPayload;
};

const makeFromWishlistItem = (
  wishlist: WishlistDetails,
): ProductEventProperties => {
  const currency = REEBELO_CURRENCY[settings.store];

  const eventPayload: ProductEventProperties = {
    product_id: wishlist.id,
    sku: wishlist.sku,
    vendor: wishlist.vendor,
    psku: wishlist.psku,
    name: wishlist.name,
    category: wishlist.category,
    brand: wishlist.brand,
    color: wishlist.color,
    condition: wishlist.condition as ReebeloConditions,
    price: Number(wishlist.minPrice || wishlist.price),
    compare_price: wishlist.price,
    currency,
    quantity: 1,
    images: [wishlist.image],
    store: settings.store,
    url: wishlist.url,
    timestamp: new Date().toISOString(),
  };

  return eventPayload;
};

export const makeProductProperties = (
  r: ProductTrackRequest,
): ProductEventProperties => {
  if (r.offer) return makeFromOffer(r.offer);
  if (r.details) return makeFromProductDetails(r.details);
  if (r.variant) return makeFromProductVariant(r.variant, r.variantAttributes);
  if (r.wishlist) return makeFromWishlistItem(r.wishlist);

  throw new Error(`unsupported product object given for tracking: ${r}`);
};

const trackProductEvent = (
  event: SegmentEvent,
  r: ProductTrackRequest,
): ProductEventProperties | null => {
  try {
    logger.debug({ event, r }, 'tracking product event');

    const properties = { ...makeProductProperties(r), ...r.properties };

    trackEvent(event, properties);

    return properties;
  } catch (error: any) {
    logger.error({ event, r, error }, 'error tracking product event');

    return null;
  }
};

const trackProductListEvent = (r: ProductListTrackRequest): void => {
  try {
    logger.debug({ r }, 'tracking product list event');

    let filters: ProductListFilterSort[];
    let sorts: ProductListFilterSort[];

    if (r.qs) {
      filters = [];
      const { sort, order, ...otherFilters } = r.qs;
      const sortMethods = ['latest-release', 'best-selling'];

      if (sort && order) sorts = [{ type: sort, value: order }];
      if (sort && sortMethods.indexOf(sort) >= 0)
        sorts = [{ type: sort, value: 'asc' }];

      // eslint-disable-next-line no-restricted-syntax
      for (const [type, value] of Object.entries(otherFilters)) {
        filters.push(
          ...value.split('|').map((v) => ({
            type,
            value: v,
          })),
        );
      }
    }

    const allProducts: ProductEventProperties[] = [];

    if (r.offers?.length)
      allProducts.push(...r.offers.map((o) => makeFromOffer(o)));

    if (r.collections?.length)
      allProducts.push(...r.collections.map((c) => makeFromCollection(c)));

    chunk(allProducts, r.limit).forEach((products) => {
      const properties: ProductListFilteredEventProperties = {
        filters,
        sorts,
        products,
      };

      trackEvent(r.event, properties);
    });
  } catch (error: any) {
    logger.error({ r, error }, 'error tracking product list event');
  }
};

export const trackViewed = (r: ProductTrackRequest): void => {
  if (!shouldTrack()) {
    logger.info({ r }, 'not tracking product viewed in segment');

    return;
  }

  const properties = trackProductEvent(SegmentEvent.PRODUCT_VIEWED, r);

  if (!properties) return;

  analytics.identify({
    last_viewed: properties,
  });
};

export const trackAddToCart = (r: ProductTrackRequest): void => {
  trackProductEvent(SegmentEvent.PRODUCT_ADDED, r);
};

export const trackAddToWishlist = (r: ProductTrackRequest): void => {
  trackProductEvent(SegmentEvent.PRODUCT_ADDED_WISHLIST, r);
};

export const trackRemoveFromWishlist = (r: ProductTrackRequest): void => {
  trackProductEvent(SegmentEvent.PRODUCT_REMOVED_WISHLIST, r);
};

export const trackRemoveFromCart = (r: ProductTrackRequest): void => {
  trackProductEvent(SegmentEvent.PRODUCT_REMOVED, r);
};

export const trackProductsSearched = (query: string): void => {
  trackEvent(SegmentEvent.PRODUCTS_SEARCHED, { query });
};

export const trackProductListViewed = (
  offers: SearchOffers[] | TopDealProduct[],
  limit = 25,
): void =>
  trackProductListEvent({
    event: SegmentEvent.PRODUCT_LIST_VIEWED,
    offers,
    limit,
  });

export const trackProductListFiltered = (
  offers: SearchOffers[],
  qs: Record<SearchKeys, string>,
  limit = 25,
): void =>
  trackProductListEvent({
    event: SegmentEvent.PRODUCT_LIST_FILTERED,
    offers,
    limit,
    qs,
  });

export const trackProductCollectionFiltered = (
  collections: EssentialSearchOffers[],
  qs: { [k: string]: string },
  limit = 25,
): void => {
  trackProductListEvent({
    event: SegmentEvent.PRODUCT_LIST_FILTERED,
    collections,
    limit,
    qs,
  });
};

export const trackProductFasterDeliveryViewed = (
  offers: SearchOffers[],
  limit = 25,
): void =>
  trackProductListEvent({
    event: SegmentEvent.PRODUCT_FASTER_DELIVERY_VIEWED,
    offers,
    limit,
  });

const trackReebeloCaretEvent = (
  event: SegmentEvent,
  props: ReebeloCareTrackRequest,
): ReebeloCareTrackRequest | null => {
  try {
    logger.debug({ event, props }, 'tracking reebelocare event');

    trackEvent(event, props);

    return props;
  } catch (error: any) {
    logger.error({ event, props, error }, 'error tracking reebelocare event');

    return null;
  }
};

export const trackReebeloCareSelected = (props: ReebeloCareTrackRequest) =>
  trackReebeloCaretEvent(SegmentEvent.REEBELOCARE_SELECTED, props);

export const trackReebeloCareDeselected = (props: ReebeloCareTrackRequest) =>
  trackReebeloCaretEvent(SegmentEvent.REEBELOCARE_DESELECTED, props);

export const trackReebeloCareAdded = (props: ReebeloCareTrackRequest) =>
  trackReebeloCaretEvent(SegmentEvent.REEBELOCARE_ADDED, props);

export const trackReebeloCareRemoved = (props: ReebeloCareTrackRequest) =>
  trackReebeloCaretEvent(SegmentEvent.REEBELOCARE_REMOVED, props);
