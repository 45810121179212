/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { uniq } from 'lodash-es';
import TEST from './TEST.json';
import { logger } from '@/lib/logger';

let Translations: Record<string, Record<string, string>> = {};

if (process.env.NODE_ENV === 'test') Translations = TEST;
type Params = Record<string, string | number>;

const interpolate = (str: string, params?: Params) => {
  if (process.env.NODE_ENV === 'development') {
    const requiredParams = (str.match(/\{\{ *(\w+) *\}\}/g) || [])
      .map((s) => s.replace('{{', '').replace('}}', '').trim())
      .sort();
    const recievedParameters = Object.keys(params || {}).sort();

    if (uniq(requiredParams).join('#') !== uniq(recievedParameters).join('#')) {
      logger.warn(
        { requiredParams, recievedParameters },
        `Translation for "${str}" expected parameters do not match the received parameters`,
      );
    }
  }

  if (params == null || !Object.entries(params).length) return str;

  return Object.entries(params).reduce(
    (text, [key, value]) =>
      text.replace(
        new RegExp(String.raw`\{\{ *${key} *\}\}`, 'g'),
        (value || '').toString(),
      ),
    str,
  );
};

const normalize = (str: string) => str.replace(/\n/g, ' ').replace(/ +/g, ' ');

/**
 * Define a i18n namespace
 */
// eslint-disable-next-line no-underscore-dangle
const _namespace = (name: string) => {
  const translations = Translations[name] || {};

  function t(_template: TemplateStringsArray | string, ...params: Params[]) {
    const template =
      typeof _template === 'string' ? [_template] : _template || []; // _template can be string to allow `t(someVariable)`

    if (process.env.NODE_ENV === 'development') {
      const str = `\nString in /pages/${name}:\n\t"${template.join(' ... ')}"`;

      if (
        template.some(
          (i) => i.includes('{{') || i.includes('}}') || /\{[^ ]*\}/.test(i),
        )
      ) {
        throw Error(
          `A translated string contains \`{...}\`, \`{{\`or  \`}}\` which means they are not properly interpolted. Please use \`\${{ key: value }}\` instead.${str}`,
        );
      }
    }

    const str = normalize(
      template
        .flatMap((chunk, i) => {
          if (i === template.length - 1) return chunk;

          return `${chunk}{{${Object.keys(params[i])[0]}}}`;
        })
        .join(''),
    );
    const translated = normalize(translations[str] || str);
    const param = params.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    return interpolate(translated, param);
  }

  return t;
};

export default {
  interpolate,
  _namespace,
  init(): ReturnType<typeof _namespace> {
    throw Error(
      'Init was not propery initialize. Make sure it is called as `const t = i18n.init( );` the formulation is important. Check next.config.js for more information.',
    );
  },
};
