import settings from '@/settings';

let name = '';

switch (settings.store) {
  case 'reebelo-us':
    name = 'US';
    break;
  case 'reebelo-au':
    name = 'Australia';
    break;
  case 'reebelo-nz':
    name = 'New Zealand';
    break;
  case 'reebelo-ca':
    name = 'Canada';
    break;
  default:
    name = 'Singapore';
    break;
}
const storeName = `Reebelo ${name}`;

export function WebsiteSchema() {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: storeName,
  };

  return (
    <script
      key="structured-data"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
}
